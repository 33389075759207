<template>
  <div id="analysis">
    <Breadcrumb />

    <div class="items-center mb-6">
      <h1
        class="mb-3 text-3xl font-semibold text-gray-800  dark:text-gray-300 lg:mb-0"
      >
        Terms of Service
      </h1>
      <p class="dark:text-gray-300">//To do..</p>
      <hr class="mt-2" />
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";

export default {
  components: {
    Breadcrumb,
  },
};
</script>

<style>
</style>